// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../utility/constants'


export const getAgedStockFilterList = createAsyncThunk('AgedStockManagement/getAgedStockFilterDataJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockFilterDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockFilterData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          itemCategoryList: response1.data.body && response1.data.body.itemCategoryList ? response1.data.body.itemCategoryList : [],
          brandList: response1.data.body && response1.data.body.brandList ? response1.data.body.brandList : [],
          designList: response1.data.body && response1.data.body.designList ? response1.data.body.designList : []
        }
      } else {
        return {
          itemCategoryList: response1.data.body ? response1.data.body.itemCategoryList : [],
          brandList: response1.data.body ? response1.data.body.brandList : [],
          designList: response1.data.body ? response1.data.body.designList : []
        }
      }
    } else {
      return {
        itemCategoryList: response.data.body ? response.data.body.itemCategoryList : [],
        brandList: response.data.body ? response.data.body.brandList : [],
        designList: response.data.body ? response.data.body.designList : []
      }
    }
  } catch (error) {
    return {
      itemCategoryList: [],
      brandList: [],
      designList: []
    }
  }
})


export const getAgedStockData = createAsyncThunk('AgedStockManagement/getAgedStockListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          agedStockArray: response1.data.body && response1.data.body.AgedStockList ? response1.data.body.AgedStockList : [],
          TotalAgedStockList: response1.data.body && response1.data.body.TotalAgedStockList ? response1.data.body.TotalAgedStockList : 0,
          totalAgedStock: response1.data.body && response1.data.body.totalAgedStock ? response1.data.body.totalAgedStock : {}
        }
      } else {
        return {
          agedStockArray: response1.data.body ? response1.data.body.AgedStockList : [],
          TotalAgedStockList: response1.data.body ? response1.data.body.TotalAgedStockList : 0,
          totalAgedStock: response1.data.body ? response1.data.body.totalAgedStock : {}
        }
      }
    } else {
      return {
        agedStockArray: response.data.body ? response.data.body.AgedStockList : [],
        TotalAgedStockList: response.data.body ? response.data.body.TotalAgedStockList : 0,
        totalAgedStock: response.data.body ? response.data.body.totalAgedStock : {}
      }
    }
  } catch (error) {
    return {
      agedStockArray: [],
      TotalAgedStockList: 0,
      totalAgedStock: {}
    }
  }
})

/******************* Aged  Stock *******************/

export const getNewAgedStockFilterList = createAsyncThunk('AgedStockManagement/getNewAgedStockFilterDataJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/getNewAgedStockFilterDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/getNewAgedStockFilterData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          itemCategoryList: response1.data.body && response1.data.body.itemCategoryList ? response1.data.body.itemCategoryList : [],
          brandList: response1.data.body && response1.data.body.brandList ? response1.data.body.brandList : [],
          designList: response1.data.body && response1.data.body.designList ? response1.data.body.designList : []
        }
      } else {
        return {
          itemCategoryList: response1.data.body ? response1.data.body.itemCategoryList : [],
          brandList: response1.data.body ? response1.data.body.brandList : [],
          designList: response1.data.body ? response1.data.body.designList : []
        }
      }
    } else {
      return {
        itemCategoryList: response.data.body ? response.data.body.itemCategoryList : [],
        brandList: response.data.body ? response.data.body.brandList : [],
        designList: response.data.body ? response.data.body.designList : []
      }
    }
  } catch (error) {
    return {
      itemCategoryList: [],
      brandList: [],
      designList: []
    }
  }
})

export const getAgedStockList = createAsyncThunk('AgedStockManagement/agedStockListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/agedStockListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/agedStockList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          AgedStockList: response1.data.body && response1.data.body.AgedStockList ? response1.data.body.AgedStockList : []
        }
      } else {
        return {
          ClosePendingOrderList: response1.data.body ? response1.data.body.AgedStockList : []
        }
      }
    } else {
      return {
        AgedStockList: response.data.body ? response.data.body.AgedStockList : []
      }
    }
  } catch (error) {
    return {
      AgedStockList: []
    }
  }
})


export const saveAgedStockData = createAsyncThunk('AgedStockManagement/saveAgedStockList', async params => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/saveAgedStockListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/saveAgedStockList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          agedStockStatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          agedStockMessage: response1.data.body.message
        }
      } else {
        return {
          agedStockStatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          agedStockMessage: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        agedStockStatusFlag: response.data.body ? response.data.body.statusFlag : 2,
        agedStockMessage: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      agedStockStatusFlag: 2,
      agedStockMessage: 'Server not reachable.Please try again later'
    }
  }
})

export const getAgedStockForDesignID = createAsyncThunk('AgedStockManagement/getAgedStockForDesignID', async params => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockForDesignIDJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/getAgedStockForDesignID`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          agentList: response1.data.body && response1.data.body.agentList ? response1.data.body.agentList : [],
          designID_AgedStock: response1.data.body && response1.data.body.agedStockList ? response1.data.body.agedStockList : [],
          allotedAgedStockList: response1.data.body && response1.data.body.allotedAgedStockList ? response1.data.body.allotedAgedStockList : []
        }
      } else {
        return {
          agentList: response1.data.body ? response1.data.body.agentList : [],
          designID_AgedStock: response1.data.body ? response1.data.body.agedStockList : [],
          allotedAgedStockList: response1.data.body ? response1.data.body.allotedAgedStockList : []
        }
      }
    } else {
      return {
        agentList: response.data.body ? response.data.body.agentList : [],
        designID_AgedStock: response.data.body ? response.data.body.agedStockList : [],
        allotedAgedStockList: response.data.body ? response.data.body.allotedAgedStockList : []
      }
    }
  } catch (error) {
    return {
      agentList: [],
      designID_AgedStock: [],
      allotedAgedStockList: []
    }
  }
})

export const mapAgedStockToAgent = createAsyncThunk('AgedStockManagement/mapAgedStockToAgentJwt', async params => {
  try {
    const response = await axios.post(`${api.api_url}/agedStockManagement/mapAgedStockToAgentJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/agedStockManagement/mapAgedStockToAgent`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          mapAgedStockToAgentStatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          mapAgedStockToAgentMessage: response1.data.body.message
        }
      } else {
        return {
          mapAgedStockToAgentStatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          mapAgedStockToAgentMessage: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        mapAgedStockToAgentStatusFlag: response.data.body ? response.data.body.statusFlag : 2,
        mapAgedStockToAgentMessage: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      mapAgedStockToAgentStatusFlag: 2,
      mapAgedStockToAgentMessage: 'Server not reachable.Please try again later'
    }
  }
})

export const AgedStockManagementSlice = createSlice({
  name: 'AgedStockManagement',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    BrandArray: [],
    ColorArray: [],
    statuslist: [],
    ItemArray: [],
    statusFlag: 0,
    checkFlag: 0,
    statuscode: 0,
    itemManagementlist: [],
    TotalItem: 0,
    SizeArray: [],
    EditSizeList: [],
    EditItemArray: [],
    check_single_statusFlag: 0,
    check_single_message: 0,
    brandWiseTotalList: [],

    AgedStockList: [],
    TotalAgedStockList: 0,
    totalAgedStock: {},
    agedStockStatusFlag: 0,
    agedStockMessage: "",
    itemCategoryList: [],
    brandList: [],
    designList: [],
    agedStockArray: [],
    agentList: [],
    designID_AgedStock: [],
    allotedAgedStockList: [],
    mapAgedStockToAgentStatusFlag: 0,
    mapAgedStockToAgentMessage: ""
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.check_single_statusFlag = action.payload.check_single_statusFlag
      state.check_single_message = action.payload.check_single_message
      state.agedStockStatusFlag = action.payload.agedStockStatusFlag
      state.agedStockMessage = action.payload.agedStockMessage
      state.mapAgedStockToAgentStatusFlag = action.payload.mapAgedStockToAgentStatusFlag
      state.mapAgedStockToAgentMessage = action.payload.mapAgedStockToAgentMessage
    },
    handleCheckFlag: (state, action) => {
      state.checkFlag = action.payload.checkFlag
    },
    clearStoreData: (state, action) => {
      state.ItemArray = action.payload.ItemArray
      state.AgedStockList = action.payload.AgedStockList
      state.agentList = action.payload.agentList
      state.designID_AgedStock = action.payload.designID_AgedStock
      state.allotedAgedStockList = action.payload.allotedAgedStockList
    }
  },
  extraReducers: builder => {
    builder.addCase(getAgedStockFilterList.fulfilled, (state, action) => {
      state.itemCategoryList = action.payload.itemCategoryList
      state.brandList = action.payload.brandList
      state.designList = action.payload.designList
    }).addCase(getNewAgedStockFilterList.fulfilled, (state, action) => {
      state.itemCategoryList = action.payload.itemCategoryList
      state.brandList = action.payload.brandList
      state.designList = action.payload.designList
    }).addCase(getAgedStockData.fulfilled, (state, action) => {
      state.agedStockArray = action.payload.agedStockArray
      state.TotalAgedStockList = action.payload.TotalAgedStockList
      state.totalAgedStock = action.payload.totalAgedStock
    }).addCase(getAgedStockList.fulfilled, (state, action) => {
      state.AgedStockList = action.payload.AgedStockList
    })
      .addCase(saveAgedStockData.fulfilled, (state, action) => {
        state.agedStockStatusFlag = action.payload.agedStockStatusFlag
        state.agedStockMessage = action.payload.agedStockMessage
      })
      .addCase(getAgedStockForDesignID.fulfilled, (state, action) => {
        state.agentList = action.payload.agentList
        state.designID_AgedStock = action.payload.designID_AgedStock
        state.allotedAgedStockList = action.payload.allotedAgedStockList
      })
      .addCase(mapAgedStockToAgent.fulfilled, (state, action) => {
        state.mapAgedStockToAgentStatusFlag = action.payload.mapAgedStockToAgentStatusFlag
        state.mapAgedStockToAgentMessage = action.payload.mapAgedStockToAgentMessage
      })
  }
})
export const {
  handleStatusFlag, handleCheckFlag, clearStoreData
} = AgedStockManagementSlice.actions
export default AgedStockManagementSlice.reducer
