// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import EmployeeDetails from '@src/views/employee/employee/store'
import CompanyMaster from '@src/views/masters/companyMaster/store'
import ItemgroupMaster from '@src/views/masters/itemgroup/store'
import ItemMaster from '@src/views/masters/item/store'
import ColorMaster from '@src/views/masters/color/store'
import AgentMaster from '@src/views/masters/agent/store'
import UserMaster from '@src/views/masters/usermanagement/store'
import DesignationMaster from '@src/views/employee/designation/store'
import DepartmentMaster from '@src/views/employee/department/store'
import BoxingDataList from '@src/views/dispatch/boxing/store'
import CurrentStockData from '@src/views/dispatch/currentStock/store'
import MachineMaster from '@src/views/production/machine/store'
import DesignMaster from '@src/views/production/design/store'
import JobCard from '@src/views/production/jobcard/store'
import RateCard from '@src/views/production/ratecard/store'
import SalaryPayout from '@src/views/payroll/salarypayout/store'
import SalaryProcess from '@src/views/payroll/salaryprocess/store'
import JobCuttingSalaryProcess from '@src/views/payroll/jobcuttingsalaryprocess/store'
import JobCuttingSalaryPayout from '@src/views/payroll/jobcuttingsalarypayout/store'
import PayoutReport from '@src/views/reports/payout_report/store'
import PendingJobReport from '@src/views/reports/pendingjob/store'
import PerformaceReport from '@src/views/reports/performance/store'
import Dashboard from '@src/views/dashboard/store'
import CustomerMaster from '@src/views/orderManagement/customer/store'
import ItemManagement from '@src/views/orderManagement/item_management/store'
import OrderTracking from '@src/views/orderManagement/order_tracking/store'
import CustomerWiseOrder from '@src/views/reports/order/order_wise/store'
import ItemWiseOrder from '@src/views/reports/order/item_wise/store'
import CustomerOrder from '@src/views/reports/order/customer_wise/store'
import CurrentStockOrder from '@src/views/reports/order/current_stock/store'
import DispatchDataList from '@src/views/dispatch/newDispatch/store'
import DispatchListData from '@src/views/dispatch/dispatchList/store'
import IronmachineMaster from '@src/views/masters/ironmachine/store'
import FinishedGoodList from '@src/views/dispatch/finishedGoodsList/store'
import PendingOrderList from '@src/views/reports/pendingorder/store'
import CustomerWiseReport from '@src/views/reports/detailed_report/store'
import GoodsReturnData from '@src/views/dispatch/goodsreturn/store'
import ItemsubgroupMaster from '@src/views/masters/itemsubgroup/store'
import JobCutting from '@src/views/production/jobcutting/store'
import JobCuttingPerformanceReport from '@src/views/reports/performanceforcutting/store' 
import SupplierMaster from '@src/views/purchaseManagement/supplier/store'
import MaterialMaster from '@src/views/purchaseManagement/material/store'
import MaterialInwardData from '@src/views/purchaseManagement/materialInward/store'
import EmbroiderMaster from '@src/views/masters/embroider/store'
import PurchaseOrder from '@src/views/purchaseManagement/purchaseOrder/store'
import PurchaseReturnData from '@src/views/purchaseManagement/purchasereturn/store'
import JobEmbroider from '@src/views/production/embroider/store'
import EmbroiderSalaryProcess from '@src/views/payroll/embroidersalaryprocess/store'
import EmbroiderSalaryPayout from '@src/views/payroll/embroidersalarypayout/store'
import StockAdjustmentData from '@src/views/dispatch/stockAdjustMent/store'
import EmbroiderPerformanceReport from '@src/views/reports/performanceforEmbroider/store' 
import SupplierOrder from '@src/views/reports/purchaseManagement/supplier_wise/store'
import RateCutting from '@src/views/production/ratecutting/store'
import RateEmbroider from '@src/views/production/rateembroider/store'
import BrandMaster from '@src/views/masters/brand/store'
import OrderUserStateMapping from '@src/views/masters/userstatemapping/store'
import AgedStockManagement from '@src/views/agedstock/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  EmployeeDetails,
  CompanyMaster,
  ItemgroupMaster,
  ItemMaster,
  ColorMaster,
  UserMaster,
  DesignationMaster,
  DepartmentMaster,
  MachineMaster,
  DesignMaster,
  JobCard,
  RateCard,
  SalaryPayout,
  SalaryProcess,
  JobCuttingSalaryProcess,
  JobCuttingSalaryPayout,
  PayoutReport,
  PendingJobReport,
  PerformaceReport,
  Dashboard,
  CustomerMaster,
  ItemManagement,
  OrderTracking,
  CustomerWiseOrder,
  ItemWiseOrder,
  CustomerOrder,
  CurrentStockOrder,
  AgentMaster,
  BoxingDataList,
  CurrentStockData,
  DispatchDataList,
  DispatchListData,
  IronmachineMaster,
  FinishedGoodList,
  PendingOrderList,
  GoodsReturnData,
  ItemsubgroupMaster,
  JobCutting,  
  CustomerWiseReport,
  JobCuttingPerformanceReport,
  SupplierMaster,
  MaterialMaster,
  MaterialInwardData,
  EmbroiderMaster,
  PurchaseOrder,
  PurchaseReturnData,
  JobEmbroider,
  EmbroiderSalaryProcess,
  EmbroiderSalaryPayout,
  StockAdjustmentData,
  EmbroiderPerformanceReport,
  SupplierOrder,
  RateCutting,
  RateEmbroider,
  BrandMaster,
  OrderUserStateMapping,
  AgedStockManagement
}

export default rootReducer
