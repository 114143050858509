// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'
import { GiConsoleController } from 'react-icons/gi'

// Generate Jobno
export const generateJobno = createAsyncThunk('JobEmbroider/generateJobno', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/generateJobEmbroiderNoJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/generateJobEmbroiderNo`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          JobNo: response1.data.body && response1.data.body.JobNo ? response1.data.body.JobNo : ''
        }
      } else {
        return {
          JobNo: response1.data.body ? response1.data.body.JobNo : ''
        }
      }
    } else {
      return {
        JobNo: response.data.body ? response.data.body.JobNo : ''
      }
    }
  } catch (error) {
    return {
      JobNo: ''
    }
  }
})
export const newEmbroiderFilterData = createAsyncThunk('JobEmbroider/newEmbroiderFilter', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/newEmbroiderFilterJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/newEmbroiderFilter`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          EmbroiderList: response1.data.body && response1.data.body.EmbroiderEmployeeList ? response1.data.body.EmbroiderEmployeeList : []
          // ItemCategoryList: response1.data.body && response1.data.body.ItemList ? response1.data.body.ItemList : []
          
        }
      } else {
        return {
          EmbroiderList: response1.data.body ? response1.data.body.EmbroiderEmployeeList : []
          // ItemCategoryList: response1.data.body ? response1.data.body.ItemList : [] 
        }
      }
    } else {
      return {
        EmbroiderList: response.data.body ? response.data.body.EmbroiderEmployeeList : []
        // ItemCategoryList: response.data.body ? response.data.body.ItemList : []
      }
    }
  } catch (error) {
    return {
      EmbroiderList: []
      // ItemCategoryList: []
    }
  }
})

export const onchangeItemEmbroider = createAsyncThunk('JobEmbroider/onchangeItemEmbroiderJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/onchangeItemEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/onchangeItemEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          designList: response1.data.body && response1.data.body.DesignList ? response1.data.body.DesignList : []

        }
      } else {
        return {
          designList: response1.data.body ? response1.data.body.DesignList : []
        }
      }
    } else {
      return {
        designList: response.data.body ? response.data.body.DesignList : []
      }
    }
  } catch (error) {
    return {
      designList: []
    }
  }
})

export const getSizeListData = createAsyncThunk('JobEmbroider/getSizeListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/getSizeForEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/getSizeForEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          SizeList: response1.data.body && response1.data.body.SizeList ? response1.data.body.SizeList : []
        }
      } else {
        return {
          SizeList: response1.data.body ? response1.data.body.SizeList : []
        }
      }
    } else {
      return {
        SizeList: response.data.body ? response.data.body.SizeList : []
      }
    }
  } catch (error) {
    return {
      SizeList: []
    }
  }
})

export const getColorListData = createAsyncThunk('JobEmbroider/getColorListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/getColorsForEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/getColorsForEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ColorList: response1.data.body && response1.data.body.ColorList ? response1.data.body.ColorList : []
        }
      } else {
        return {
          ColorList: response1.data.body ? response1.data.body.ColorList : []
        }
      }
    } else {
      return {
        ColorList: response.data.body ? response.data.body.ColorList : []
      }
    }
  } catch (error) {
    return {
      ColorList: []
    }
  }
})

export const getPriceForEmbroider = createAsyncThunk('JobEmbroider/getPriceForEmbroider', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/getPriceForEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/getPriceForEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          RateList: response1.data.body && response1.data.body.RateList ? response1.data.body.RateList : []
        }
      } else {
        return {
          RateList: response1.data.body ? response1.data.body.RateList : []
        }
      }
    } else {
      return {
        RateList: response.data.body ? response.data.body.RateList : []
      }
    }
  } catch (error) {
    return {
      RateList: []
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('JobEmbroider/saveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/saveJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/saveJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message,
          saveJobData: response1.data.body.resultData
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
          saveJobData: response1.data.body ? response1.data.body.resultData : {}
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
        saveJobData: response.data.body ? response.data.body.resultData : {}
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later',
      saveJobData: {}
    }
  }
})

export const getItemListData = createAsyncThunk('JobEmbroider/getItemListData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/getItemsForJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/getItemsForJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ListItem: response1.data.body && response1.data.body.ItemList ? response1.data.body.ItemList : []
        }
      } else {
        return {
          ListItem: response1.data.body ? response1.data.body.ItemList : []
        }
      }
    } else {
      return {
        ListItem: response.data.body ? response.data.body.ItemList : []
      }
    }
  } catch (error) {
    return {
      ListItem: []
    }
  }
})

/******************* Job Embroider List *******************/
//  GET Common LIST
export const commonData = createAsyncThunk('JobEmbroider/fetchEmbroiderFilterData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/fetchEmbroiderFilterDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/fetchEmbroiderFilterData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          EmbroiderArray: response1.data.body && response1.data.body.EmbroiderArray ? response1.data.body.EmbroiderArray : [],
          ItemArray: response1.data.body && response1.data.body.ItemArray ? response1.data.body.ItemArray : [],
          allcolorArray: response1.data.body && response1.data.body.allcolorArray ? response1.data.body.allcolorArray : [],
          allitemgroupArray: response1.data.body && response1.data.body.allitemgroupArray ? response1.data.body.allitemgroupArray : [],
          alldesignArray: response1.data.body && response1.data.body.alldesignArray ? response1.data.body.alldesignArray : []
        }
      } else {
        return {
          EmbroiderArray: response1.data.body ? response1.data.body.EmbroiderArray : [],
          ItemArray: response1.data.body ? response1.data.body.ItemArray : [],
          allcolorArray: response1.data.body ? response1.data.body.allcolorArray : [],
          allitemgroupArray: response1.data.body ? response1.data.body.allitemgroupArray : [],
          alldesignArray: response1.data.body ? response1.data.body.alldesignArray : []
        }
      }
    } else {
      return {
        EmbroiderArray: response.data.body ? response.data.body.EmbroiderArray : [],
        ItemArray: response.data.body ? response.data.body.ItemArray : [],
        allcolorArray: response.data.body ? response.data.body.allcolorArray : [],
        allitemgroupArray: response.data.body ? response.data.body.allitemgroupArray : [],
        alldesignArray: response.data.body ? response.data.body.alldesignArray : []
      }
    }
  } catch (error) {
    return {
      EmbroiderArray: [],
      ItemArray: [],
      allcolorArray: [],
      allitemgroupArray: [],
      alldesignArray: []
    }
  }
})


export const JobEmbroiderlist = createAsyncThunk('JobEmbroider/jobEmbroiderList', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/jobEmbroiderListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/jobEmbroiderList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          JobEmbroiderlist: response1.data.body && response1.data.body.JobEmbroiderArray ? response1.data.body.JobEmbroiderArray : [],
          CompanyList: response1.data.body && response1.data.body.CompanyArray ? response1.data.body.CompanyArray : [],
          TotalJobEmbroider: response1.data.body && response1.data.body.TotalData ? response1.data.body.TotalData : []
        }
      } else {
        return {
          JobEmbroiderlist: response1.data.body ? response1.data.body.JobEmbroiderArray : [],
          CompanyList: response1.data.body ? response1.data.body.CompanyArray : [],
          TotalJobEmbroider: response1.data.body ? response1.data.body.TotalData : []
        }
      }
    } else {
      return {
        JobEmbroiderlist: response.data.body ? response.data.body.JobEmbroiderArray : [],
        CompanyList: response.data.body ? response.data.body.CompanyArray : [],
        TotalJobEmbroider: response.data.body ? response.data.body.TotalData : []
      }
    }
  } catch (error) {
    return {
      JobEmbroiderlist: [],
      CompanyList: [],
      TotalJobEmbroider: []
    }
  }
})

// Edit JobEmbroider LIST
export const editData = createAsyncThunk('JobEmbroider/editData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/editJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/editJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          editJobEmbroiderArray: response1.data.body && response1.data.body.editJobEmbroiderArray ? response1.data.body.editJobEmbroiderArray : []
        }
      } else {
        return {
          editJobEmbroiderArray: response1.data.body ? response1.data.body.editJobEmbroiderArray : []
        }
      }
    } else {
      return {
        editJobEmbroiderArray: response.data.body ? response.data.body.editJobEmbroiderArray : []
      }
    }
  } catch (error) {
    return {
      editJobEmbroiderArray: []
    }
  }
})


export const printJobEmbroiderData = createAsyncThunk('JobEmbroider/printJobEmbroiderData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/printJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/printJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Print_JobEmbroider_Array: response1.data.body && response1.data.body.PrintJobEmbroiderArray ? response1.data.body.PrintJobEmbroiderArray : [],
          Print_Company_Array: response1.data.body && response1.data.body.PrintCompanyArray ? response1.data.body.PrintCompanyArray : [],
          print_result: 'true'
        }
      } else {
        return {
          Print_JobEmbroider_Array: response1.data.body ? response1.data.body.PrintJobEmbroiderArray : [],
          Print_Company_Array: response1.data.body ? response1.data.body.PrintCompanyArray : [],
          print_result: 'false'
        }
      }
    } else {
      return {
        Print_JobEmbroider_Array: response.data.body ? response.data.body.PrintJobEmbroiderArray : [],
        Print_Company_Array: response.data.body ? response.data.body.PrintCompanyArray : [],
        print_result: 'false'
      }
    }
  } catch (error) {
    return {
      Print_JobEmbroider_Array: [],
      Print_Company_Array: [],
      print_result: 'false'
    }
  }
})

//DELETE EMBROIDER ENTRY
export const deleteData = createAsyncThunk('JobEmbroider/deleteData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/production/jobEmbroider/deleteJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/deleteJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})


// Machine Wise Employee search
export const onchangemachineemployeeData = createAsyncThunk('JobEmbroider/onchangemachineemployeeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        MachineEmployeeList: response1.data.body.MachineEmployeeList
      }
    } else {
    }
  }
}
)

// Machine search
export const onchangemachineData = createAsyncThunk('JobEmbroider/onchangemachineData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListMachine: response1.data.body.MachineList
      }
    } else {
    }
  }
}
)

//saveData Rate
export const saveRateData = createAsyncThunk('jobEmbroider/saveRateData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/jobEmbroider/updateRateJobEmbroiderJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobEmbroider/updateRateJobEmbroider`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      rateStatusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      rateMessage: response1.data.body.message 
    }
   } else {
    return {
      rateStatusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
      rateMessage: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      rateStatusFlag: response.data.body ? response.data.body.statusFlag : 2,
      rateMessage: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      rateStatusFlag:2,
      rateMessage:'Server not reachable.Please try again later'      
    }    
  }
})


export const getBrandList = createAsyncThunk('jobEmbroider/brandFilterListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/common/brandFilterListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/common/brandFilterList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      brand_List:response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
      all_Brand_List: response1.data.body && response1.data.body.All_Brand_Array ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body.message
    }
   } else {
    return {
      brand_List:response1.data.body ? response1.data.body.BrandArray : [],
      all_Brand_List: response1.data.body ? response1.data.body.All_Brand_Array : [],
      brand_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      brand_List:response.data.body ? response.data.body.BrandArray : [],
      all_Brand_List: response.data.body ? response.data.body.All_Brand_Array : [],
      brand_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      brand_List: [],
      all_Brand_List: [],
      brand_List_Message:'Server not reachable.Please try again later'
    }
  }
})

//getItemCategoryBasedOnBrand Api
export const getItemCategoryBasedOnBrand = createAsyncThunk('jobEmbroider/getItemCategoryBasedOnBrandJwt', async params => {
  const response = await axios.post(`${api.api_url}/orderManagement/itemManagement/getItemCategoryBasedOnBrandJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/itemManagement/getItemCategoryBasedOnBrand`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      ItemCategoryList:response1.data.body.DefItemArray
    }
   } 
  } else {
    return {
      ItemCategoryList: []
    }
   }
})

export const JobEmbroiderSlice = createSlice({
  name: 'JobEmbroider',
  initialState: {
    data: [],
    total: 1,
    params: {},
    designList: [],
    JobNo: '',
    SizeList: [],
    ListItem: [],
    ColorList: [],
    RateList: [],
    JobEmbroiderlist: [],
    EmbroiderList: [],
    ItemCategoryList: [],
    editJobEmbroiderArray: [],
    allData: [],
    CompanyList: [],
    TotalJobEmbroider: {},
    statuslist: [],
    statusFlag: 0,
    saveJobData: {},
    statuscode: 0,    
    EmbroiderArray: [],
    ItemArray: [],
    ListDesign: [],
    ListMachine: [],
    ListEmployee: [],
    allcolorArray: [],
    allitemgroupArray: [],
    alldesignArray: [],
    MachineEmployeeList: [],
    jobId: '',
    rateStatusFlag: 0,
    rateMessage: '',
    brand_List: [],
    all_Brand_List: [],
    brand_List_Message:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleRateStatusFlag: (state, action) => {
      state.rateStatusFlag = action.payload.rateStatusFlag
    },
    clearstoreData: (state, action) => {
      state.JobNo = action.payload.JobNo      
      state.RateList = action.payload.RateList
      state.EmbroiderList = action.payload.EmbroiderList
      state.ItemCategoryList = action.payload.ItemCategoryList
      state.designList = action.payload.designList
      state.SizeList = action.payload.SizeList
      state.ListItem = action.payload.ListItem
      state.ColorList = action.payload.ColorList
      state.editJobEmbroiderArray = action.payload.editJobEmbroiderArray
      state.Print_JobEmbroider_Array = action.payload.Print_JobEmbroider_Array
      state.Print_Company_Array = action.payload.Print_Company_Array
      state.print_result = action.payload.print_result     
      state.EmbroiderArray = action.payload.EmbroiderArray
      state.ListSize = action.payload.ListSize
      state.MachineEmployeeList = action.payload.MachineEmployeeList
      state.ListMachine = action.payload.ListMachine
      state.JobEmbroiderlist = action.payload.JobEmbroiderlist
      state.CompanyList = action.payload.CompanyList
      state.TotalJobEmbroider = action.payload.TotalJobEmbroider

    },
    handlepage: (state, action) => {
      state.jobId = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(generateJobno.fulfilled, (state, action) => {
      state.JobNo = action.payload.JobNo
    }).addCase(newEmbroiderFilterData.fulfilled, (state, action) => {
      state.EmbroiderList = action.payload.EmbroiderList
      // state.ItemCategoryList = action.payload.ItemCategoryList
    }).addCase(onchangeItemEmbroider.fulfilled, (state, action) => {
      state.designList = action.payload.designList
    }).addCase(getSizeListData.fulfilled, (state, action) => {
      state.SizeList = action.payload.SizeList
    }).addCase(getPriceForEmbroider.fulfilled, (state, action) => {
      state.RateList = action.payload.RateList
    }).addCase(getColorListData.fulfilled, (state, action) => {
      state.ColorList = action.payload.ColorList
    }).addCase(getItemListData.fulfilled, (state, action) => {
      state.ListItem = action.payload.ListItem
    }).addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.saveJobData = action.payload.saveJobData
    }).addCase(JobEmbroiderlist.fulfilled, (state, action) => {
      state.JobEmbroiderlist = action.payload.JobEmbroiderlist
      state.CompanyList = action.payload.CompanyList
      state.TotalJobEmbroider = action.payload.TotalJobEmbroider
      state.params = action.payload.params
    }).addCase(editData.fulfilled, (state, action) => {
      state.editJobEmbroiderArray = action.payload.editJobEmbroiderArray
      state.params = action.payload.params
    }).addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
      state.statusFlag = action.payload.statusFlag
    }).addCase(printJobEmbroiderData.fulfilled, (state, action) => {
      state.Print_JobEmbroider_Array = action.payload.Print_JobEmbroider_Array
      state.Print_Company_Array = action.payload.Print_Company_Array
      state.print_result = action.payload.print_result
    }).addCase(commonData.fulfilled, (state, action) => {       
      state.EmbroiderArray = action.payload.EmbroiderArray
      state.ItemArray = action.payload.ItemArray
      state.allcolorArray = action.payload.allcolorArray
      state.allitemgroupArray = action.payload.allitemgroupArray
      state.alldesignArray = action.payload.alldesignArray
      state.params = action.payload.params
    }).addCase(onchangemachineemployeeData.fulfilled, (state, action) => {
      state.MachineEmployeeList = action.payload.MachineEmployeeList
    }).addCase(onchangemachineData.fulfilled, (state, action) => {
      state.ListMachine = action.payload.ListMachine
    }).addCase(saveRateData.fulfilled, (state, action) => {
      state.rateStatusFlag = action.payload.rateStatusFlag
      state.rateMessage = action.payload.rateMessage
    }).addCase(getBrandList.fulfilled, (state, action) => {
      state.brand_List = action.payload.brand_List
      state.brand_List_Message = action.payload.brand_List_Message
      state.all_Brand_List = action.payload.all_Brand_List
    }).addCase(getItemCategoryBasedOnBrand.fulfilled, (state, action) => {
      state.ItemCategoryList = action.payload.ItemCategoryList
    })
  }
})
export const {
  handleStatusFlag, clearstoreData, handlepage, handleRateStatusFlag
} = JobEmbroiderSlice.actions
export default JobEmbroiderSlice.reducer
